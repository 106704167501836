import { Auth } from "aws-amplify";
import axios from "axios";

const PROJECT_API_BASE_URL = process.env.MAIN_API;

class ProjectManagementService {
  async getFullClients(clientName) {
    let myInit = {
      params: {
        query: `${clientName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      PROJECT_API_BASE_URL + "/new-project/search/all-clients",
      myInit
    );
  }

  async createNewProject(client, project, client_template, project_template) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = { client, project, client_template, project_template };
    return await axios.post(
      PROJECT_API_BASE_URL + "/new-project/new",
      body,
      myInit
    );
  }
  async getAllClients(clientName) {
    let myInit = {
      params: {
        query: `${clientName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      PROJECT_API_BASE_URL + "/new-project/search/clients",
      myInit
    );
  }
  async getManifestProfiles(client) {
    let myInit = {
      params: {
        client,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      PROJECT_API_BASE_URL + "/manifest-layout/profiles",
      myInit
    );
  }
  async getMappingProfile(client, profile) {
    let myInit = {
      params: {
        client,
        profile,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      PROJECT_API_BASE_URL + "/manifest-layout/profile",
      myInit
    );
  }
  async previewOrig(file) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("file_obj", file);

    return await axios.post(
      PROJECT_API_BASE_URL + "/manifest-layout/map/preview/orig",
      formData,
      myInit
    );
  }
  async mapManifestExport(file, mapping) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("mapping", JSON.stringify(mapping));

    return await axios.post(
      PROJECT_API_BASE_URL + "/manifest-layout/map/preview/file",
      formData,
      myInit
    );
  }
  async newManifestProfile(client, name, mapping) {
    let body = {
      client: client,
      name: name,
      mapping: JSON.stringify(mapping),
    };
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.post(
      PROJECT_API_BASE_URL + "/manifest-layout/new/profile",
      body,
      myInit
    );
  }
  async mapManifestPreview(file, mapping) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("mapping", JSON.stringify(mapping));

    return await axios.post(
      PROJECT_API_BASE_URL + "/manifest-layout/map/preview/table",
      formData,
      myInit
    );
  }

  async getOnboardingDetails(endpoint) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return await axios.get(PROJECT_API_BASE_URL + endpoint, myInit);
  }
  async updateOnboardingDetails(endpoint, data) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return await axios.post(PROJECT_API_BASE_URL + endpoint, data, myInit);
  }
  async createOnboardingDetails(endpoint, data) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return await axios.post(PROJECT_API_BASE_URL + endpoint, data, myInit);
  }
}

export default new ProjectManagementService();
